// @flow
import React, { memo } from "react"
import { RichText } from "prismic-reactjs"

import {
  htmlSerializerUpdateStyle,
  linkResolver,
} from "@lesmills/gatsby-theme-common"

type ReviewProps = {|
  review?: Object,
  reviewClassNames?: string,
  starClassName?: string,
  className?: string,
|}

const Review = ({
  review = { name: {}, review: {}, rating: {}, quote: {} },
  reviewClassNames = "",
  starClassName,
  className,
}: ReviewProps) => {
  const stars = []
  const rating = isNaN(review.rating) ? review.rating.text : review.rating

  for (let i = 0; i < rating; i++) {
    stars.push(
      <span
        key={`rating-${i}`}
        className={`fa fa-star checked ${starClassName || "text-white"}`}
      />
    )
  }
  return (
    <div className={className}>
      <div className="ls-5 flex">{stars}</div>
      <h2 className="mb-24 lg:mt-27 mt-24 font-primary leading-28 text-3xl uppercase text-left">
        {review.quote && review.quote.text}
      </h2>
      <RichText
        render={review.review.raw}
        linkResolver={linkResolver}
        htmlSerializer={htmlSerializerUpdateStyle(
          "",
          reviewClassNames || "text-3xs font-base-medium font-medium leading-22"
        )}
      />
      <p className="font-inter-regular mt-24 text-xs leading-18 opacity-50 text-left">
        {review.name && review.name.text}
      </p>
    </div>
  )
}

export default memo<ReviewProps>(Review)
