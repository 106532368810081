// @flow
import React, { memo } from "react"
import LazyLoad from "react-lazyload"

import DefaultSection from "../DefaultSection"

import StreamPlatforms from "./StreamPlatforms"

type Props = {|
  data?: object,
  offersPage: boolean,
  sectionClassNames?: object,
  isStream?: boolean,
  platforms?: Array<object>,
  wrapperClassNames?: string,
  classNames?: Object,
  subtitleClassName?: string,
|}

const TrainYourWay = ({
  data = {
    items: [],
    primary: {
      title: {},
      subtitle: {},
      photo: {
        url: "",
        alt: "",
      },
    },
  },
  wrapperClassNames = "",
  offersPage = false,
  sectionClassNames,
  isStream = false,
  platforms = [],
  classNames = {},
  subtitleClassName,
}: Props) => {
  const { primary = {}, items = [] } = data
  const { title = {}, subtitle = {}, photo = {} } = primary

  return (
    <DefaultSection
      isPrimary
      testId="train-your-way-section"
      classNames={
        wrapperClassNames ||
        (!offersPage ? "md:pt-110" : "pb-40 pt-40 md:pb-150 pt-88 md:pt-96")
      }
      subtitleClassNames={!offersPage ? "" : "md:text-xl"}
      sectionClassNames={sectionClassNames}
      id="watch"
    >
      <LazyLoad once>
        <div className="md:flex md:justify-center">
          <img src={photo.url} alt={photo.alt} />
        </div>
      </LazyLoad>
      <h4 className="uppercase font-primary text-6xl sm:leading-28 leading-30 text-gray-800">
        {title.text}
      </h4>
      <p
        className={`text-3xs text-gray-800 sm:text-base leading-22 mt-20 mb-40 ${subtitleClassName ||
          "font-inter-regular"}`}
      >
        {subtitle.text}
      </p>
      {isStream ? (
        <StreamPlatforms platforms={platforms} classNames={classNames} />
      ) : (
        <div
          className="flex flex-wrap justify-between ml-x-15 mr-x-15 mt-20 md:justify-center md:flex-row md:ml-0 md:mr-0 md:mt-30"
          data-cy="app-stores-section"
        >
          {items.map((item, key) => (
            <div
              key={"appstore-" + key}
              className="max-w-117 max-h-42 mb-20 md:max-w-168 md:max-h-60 md:mb-0 md:mr-10"
              data-cy="app-store"
            >
              <a href={item.url.url} target="_blank" rel="noopener">
                <img
                  src={item.app_store_image.url}
                  alt={item.app_store_image.alt}
                />
              </a>
            </div>
          ))}
        </div>
      )}
    </DefaultSection>
  )
}

export default memo<Props>(TrainYourWay)
