import React, { useState, memo } from "react"
import LazyLoad from "react-lazyload"
import isEqual from "lodash/isEqual"
import Slider from "react-slick"

import DefaultSection from "../DefaultSection"
import EmbedVideoModal from "../EmbedVideo/EmbedVideoModal"
import Video from "../EmbedVideo/Video"
import { NextArrow, PrevArrow } from "../SliderItems/Arrow"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

type Props = {|
  data: Object,
  title: Object,
  subtitle: Object,
  className?: string,
  subtitleClassName?: string,
  sliderClassName?: string,
  titleClassName?: string,
|}

const WorkoutVideos = ({
  data,
  title = {},
  subtitle = {},
  className = "",
  subtitleClassName = "",
  sliderClassName = "",
  titleClassName = "",
}: Props) => {
  const [videos = {}] = data || []
  const items = videos.items || []
  const [video, setVideo] = useState(null)

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 6,
    swipeToSlide: true,
    className: "w-full mt-32 overflow-hidden",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    adaptiveHeight: true,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1919,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          centerMode: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          centerMode: true,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          nextArrow: null,
          prevArrow: null,
          centerMode: true,
        },
      },
    ],
  }

  return (
    <DefaultSection
      title={title.text}
      subtitle={subtitle.text}
      isPrimary
      classNames={`mx-auto ${className || " py-88 md:py-96"}`}
      sectionClassNames={{
        title: `leading-30 font-primary text-6xl text-gray-800 sm:max-w-9/10 uppercase mb-16 mx-auto ${titleClassName ||
          "max-w-286"}`,
        subtitle: `leading-22 font-inter-regular text-base text-gray-800 px-20${subtitleClassName}`,
      }}
      id="workout"
    >
      {!!video && (
        <EmbedVideoModal video={video} handleDismiss={() => setVideo(null)} />
      )}
      <div className={sliderClassName}>
        <LazyLoad once>
          <Slider {...settings}>
            {items &&
              items.map((item, index) => (
                <Video
                  data={item}
                  setVideo={setVideo}
                  key={"embed-video-" + index}
                />
              ))}
          </Slider>
        </LazyLoad>
      </div>
    </DefaultSection>
  )
}

export default memo(WorkoutVideos, (prevProps, nextProps) => {
  return isEqual(prevProps.data, nextProps.data)
})
