import React from "react"
import { RichText } from "prismic-reactjs"
import LazyLoad from "react-lazyload"
import {
  htmlSerializerUpdateStyle,
  formatRichTextLink,
  Button,
} from "@lesmills/gatsby-theme-common"

type Props = {
  data: Object,
  isOfferPage: boolean,
  classNames: object,
  subtitleClassName?: string,
  textNoteClassName?: string,
  handleRedeemNow?: () => {},
}

const TryTheApp = ({
  data,
  isOfferPage,
  classNames = {},
  handleRedeemNow,
}: Props) => {
  const {
    try_the_app_subtitle = {},
    try_the_app_image = {},
    try_the_app_title = {},
    start_free_trial_button_label = {
      raw: [],
    },
    see_prices_button_label = {
      raw: [],
    },
    cancel_free_trial_note = {
      raw: [],
    },
    redeem_button = {},
  } = data || {}

  return (
    <div
      className={`flex flex-col md:flex-row text-center items-center bg-white lg:max-w-780 2xl:max-w-820 sm:max-w-80/100 sm:mx-auto mx-20 sm:justify-center ${classNames.wrapper ||
        "pt-60 mb-80 md:mb-104 lg:mb-0"}`}
    >
      <div
        className={`flex flex-col text-center lg:text-left justify-center ${classNames.content ||
          "max-w-415"}`}
      >
        <h3
          className={`text-gray-800 font-primary text-3xl sm:text-6xl leading-30 uppercase lg:text-left text-center mb-25 mx-auto lg:ml-0 ${classNames.title ||
            "max-w-70/100 sm:max-w-40/100 lg:max-w-full"}`}
        >
          {try_the_app_title.text}
        </h3>
        <h4
          className={`font-inter-regular text-gray-800 leading-22 text-base ${classNames.subtitle ||
            ""}`}
        >
          {try_the_app_subtitle.text}
        </h4>
        {isOfferPage ? (
          <div className="flex justify-center lg:justify-start">
            <Button
              handleOnClick={handleRedeemNow}
              className="mt-25 uppercase text-black bg-orange-400 w-full md:w-252 hover:text-white hover:bg-gray-970 cursor-pointer text-base font-primary sm:text-3xs text-base sm:mb-20 mb-10 h-logo-lmod-lg leading-20"
            >
              {redeem_button.text}
            </Button>
          </div>
        ) : (
          <div className="flex sm:flex-row flex-col items-center md:justify-between justify-center sm:max-w-600 sm:mx-auto sm:ml-0 mt-25 text-center xs_sm:flex-row">
            {formatRichTextLink(
              start_free_trial_button_label.raw,
              "uppercase text-black bg-green-460 w-full sm:w-202 hover:text-white hover:bg-gray-970 cursor-pointer text-base font-primary py-12 sm:mb-20 mb-10 no-underline leading-20 sm:mr-10 xs_sm:mr-10"
            )}
            {formatRichTextLink(
              see_prices_button_label.raw,
              "uppercase bg-black border-green-460 w-full sm:w-202 text-green-460 cursor-pointer text-base box-content py-12 font-primary border sm:mb-20 mb-10 no-underline leading-20"
            )}
          </div>
        )}
        <RichText
          render={cancel_free_trial_note.raw}
          htmlSerializer={htmlSerializerUpdateStyle(
            "",
            `leading-18 ml-auto mr-auto lg:ml-0 ${classNames.textNote ||
              "max-w-70/100 text-3xs text-gray-800 font-inter-regular"}`
          )}
        />
      </div>
      <div className={`hidden lg:block sm:ml-175${classNames.image || ""}`}>
        <LazyLoad once height="h-full">
          <img
            src={try_the_app_image.url}
            alt={try_the_app_image.alt}
            className="xl:max-h-658"
          />
        </LazyLoad>
      </div>
    </div>
  )
}

export default TryTheApp
